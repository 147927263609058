.ism-nav__tab {
  display: inline-block;
  padding: 0 1.8rem;
  background: rgba(26, 30, 39, 0.85);

  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 4.7rem;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .ism-nav__tab {
    padding-inline-end: 2rem;
    padding-inline-start: 2rem;
  }
}

.ism-nav__tab.active {
  color: white;
  background: #30405f;
}

.ism-nav__tab:hover {
  color: white;
  background-color: #30405f;
}
